span {
  display: block;
  overflow: hidden;
}

.loader-wrap, .loader-wrap2 {
  height: 130%;
  width: 100%;
  background: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.loader-wrap svg {
  width: 600%;
  height: 300%;
  fill: #7d938a;
  z-index: 102;
  position: absolute;
  top: 0;
}

.loader-wrap2 svg {
  width: 600%;
  height: 300%;
  fill: #fff;
  z-index: 101;
  position: absolute;
  top: 0;
}

.container {
  height: 300%;
  width: 150%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 767px) {
  .loader-wrap svg {
    width: 350%;
  }
}

@media (min-width: 1024px) {
  .loader-wrap svg {
    width: 450%;
  }
}

@media (min-width: 1600px) {
  .loader-wrap svg {
    width: 550%;
  }
}

@media (min-width: 767px) {
  .loader-wrap2 svg {
    width: 350%;
  }
}

@media (min-width: 1024px) {
  .loader-wrap2 svg {
    width: 450%;
  }
}

@media (min-width: 1600px) {
  .loader-wrap2 svg {
    width: 550%;
  }
}

/*# sourceMappingURL=index.ae0c22a9.css.map */
