span {
    overflow: hidden;
    display: block;
  }
  .loader-wrap {
    position: absolute;
    height: 130%;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  .loader-wrap2 {
    position: absolute;
    height: 130%;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: transparent;
  }

  .loader-wrap svg {
    position: absolute;
    top: 0;
    width: 600%;
    height: 300%;
    fill: #7d938a;
    z-index: 102;
  }

  .loader-wrap2 svg {
    position: absolute;
    top: 0;
    width: 600%;
    height: 300%;
    fill: white;
    z-index: 101;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300%;
    width: 150%;
  }
  
  @media (min-width: 767px) {
    .loader-wrap svg {
      width: 350%;
    }
  }

  @media (min-width: 1024px) {
    .loader-wrap svg {
      width: 450%;
    }
  }

  @media (min-width: 1600px) {
    .loader-wrap svg {
      width: 550%;
    }
  }

  @media (min-width: 767px) {
    .loader-wrap2 svg {
      width: 350%;
    }
  }

  @media (min-width: 1024px) {
    .loader-wrap2 svg {
      width: 450%;
    }
  }

  @media (min-width: 1600px) {
    .loader-wrap2 svg {
      width: 550%;
    }
  }
